import _compact from "lodash/compact";
import _uniq from "lodash/uniq";

import { formatDate, formatTime } from "@/utils/date";

const selectRoot = (state) => state.scheduledGamesList;
const selectPagination = (state) => selectRoot(state).pagination;

export const getGames = (state) => {
	const ids = selectPagination(state).ids;
	const games = selectRoot(state).games;

	return ids.map((id) => {
		const {
			visitor,
			home,
			scheduledStartTime,
			number,
			location,
			seasonId,
			gameType,
			scorekeeper,
			data,
		} = games[id];

		return {
			visitor: visitor && visitor.title,
			home: home && home.title,
			divisions: _uniq(
				_compact([visitor && visitor.division, home && home.division])
			).join(", "),
			date: scheduledStartTime && formatDate(scheduledStartTime, "UTC"),
			startTime: scheduledStartTime && formatTime(scheduledStartTime, "UTC"),
			number,
			gameType,
			location,
			scorekeeper,
			seasonId,
			id,
			data,
		};
	});
};

export const getListIsLoaded = (state) => selectPagination(state).isLoaded;
export const getListIsLoading = (state) => selectPagination(state).isLoading;
export const getPageSize = (state) => selectPagination(state).pageSize;
export const getCurrentPage = (state) => selectPagination(state).currentPage;
export const getTotalPages = (state) => selectPagination(state).totalPages;
export const getTotalCount = (state) => selectPagination(state).totalCount;
export const getFilteredCount = (state) =>
	selectPagination(state).filteredCount;
