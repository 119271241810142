import React from "react";
import PropTypes from "prop-types";

import { ModalBody, ModalFooter, Button, Input, FormFeedback } from "reactstrap";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import DashboardForm from "@/components/DashboardForm";

import { useExportSeasonGamesForm } from "./hooks";

function Form({ gamesCount, close, filteredCount }) {
  const { season } = useCurrentSeasonContext();
  const { isSubmitting, submit, fields, isInvalid } = useExportSeasonGamesForm({
    seasonId: season.id
  });

  return (
    <DashboardForm isOperating={isSubmitting} onSubmit={submit}>
      <ModalBody>
        <p>
          Score sheets for <strong>{filteredCount}</strong> of <strong>{gamesCount}</strong> games from <strong>{season.title}</strong> will be exported and
          a link to download the archive will be sent to:
        </p>
        <Input
          type="email"
          name="email"
          className="export-season-games-modal__input"
          id="export-season-games-email"
          placeholder="Your email"
          {...fields.email.input}
        />

        {fields.email.input.invalid &&
          fields.email.errors.map(error => <FormFeedback key={error}>{error}</FormFeedback>)}

        <p>Would you like to continue?</p>
      </ModalBody>

      <ModalFooter className="d-flex">
        <Button type="button" color="secondary" outline onClick={close}>
          Cancel
        </Button>

        <div className="ml-auto">
          <Button type="submit" color="success" outline={isInvalid} disabled={isInvalid || filteredCount === 0}>
            {isSubmitting ? "Processing…" : "Yes, Export Score Sheets"}
          </Button>
        </div>
      </ModalFooter>
    </DashboardForm>
  );
}

Form.propTypes = {
  gamesCount: PropTypes.number.isRequired,
  close: PropTypes.func
};

export default Form;
