import moment from "moment";
import { handleActions } from "redux-actions";

import { loadingRoutine } from "../routines";
import actions from "../actions";
import { golangDateTimeIsZero } from "@/utils/timezones";

const initialState = {
	home: {
		team: "",
		division: "",
	},
	visitor: {
		team: "",
		division: "",
	},
	startTime: "",
	endTime: "",
	timeZone: "",
	offset: 0,
	scheduledStartTime: "",
	number: "",
	gameType: "",
	location: "",
	scorekeeper: {
		name: "",
		phone: "",
	},
	broadcaster: "",
	homeLabel: "",
	visitorLabel: "",
};

function reduceLoadingSuccess(state, { payload: { game } }) {
	const {
		visitorDivision,
		visitorTeam,
		homeDivision,
		homeTeam,
		gameType,
		scorekeeper,
		scheduledTimeGmt,
		timeZoneName,
	} = game;
	const scheduledStartTime =
		game.scheduledStartTime && moment(game.scheduledStartTime).utc();
	const scheduledEndTime =
		game.scheduledEndTime &&
		!golangDateTimeIsZero(game.scheduledEndTime) &&
		moment(game.scheduledEndTime).utc();

	return {
		gameType,
		scorekeeper,
		visitor: {
			division: (visitorDivision && visitorDivision.id) || "",
			team: game.data.visitorLabel
				? "TBD - Custom Label"
				: (visitorTeam && visitorTeam.id) || "",
		},
		home: {
			division: (homeDivision && homeDivision.id) || "",
			team: game.data.homeLabel
				? "TBD - Custom Label"
				: (homeTeam && homeTeam.id) || "",
		},
		number: game.number,
		location: game.location,
		scheduledStartTime: scheduledStartTime
			? scheduledStartTime.format("YYYY-MM-DD")
			: "",
		startTime: scheduledStartTime ? scheduledStartTime.format("HH:mm") : "",
		endTime: scheduledEndTime ? scheduledEndTime.format("HH:mm") : "",
		timeZone: golangDateTimeIsZero(scheduledTimeGmt) ? null : timeZoneName, // "Baffin_Island",
		offset:
			!golangDateTimeIsZero(scheduledTimeGmt) && game.scheduledStartTime
				? (scheduledStartTime.unix() - moment(scheduledTimeGmt).unix()) / 60
				: 0,
		broadcaster: game.data.broadcaster,
		homeLabel: game.data.homeLabel,
		visitorLabel: game.data.visitorLabel,
	};
}

export default handleActions(
	{
		[loadingRoutine.SUCCESS]: reduceLoadingSuccess,
		[actions.clear]: () => ({ ...initialState }),
	},
	initialState
);
