export default async function maxJerseyNumberLength(sport) {
    try {
        if (!sport) {
            throw new Error("Sport is required");
        }
        const response = await fetch('https://lookups.gamesheet.io/api/sports');
        if (!response.ok) {
            throw new Error("Failed to fetch jersey num length");
        }

        const  data  = await response.json();
        if (!data || !Array.isArray(data)) {
            throw new Error("Invalid jersey num length data");
        }
        const sportsData = data.find(item => item.key.toLowerCase() === sport.toLowerCase());
        if (!sportsData) {
            throw new Error("Sport not found in lookups");
        }
        // explicitly return the max number of digits for the sport to be able to call fetch directly
        return sportsData.settings.maxJerseyNumberLength;
    } catch (error) {
        console.error(error);
    }
}