import * as React from "react";
import useSportJerseyNumLength from "@/hooks/useSportJerseyNumLength";
import NewTeamForm from "./NewTeamForm"
export default function NewTeamFormWrapper({disallowTeamIds,changeValues, changeTeamId, values, seasonId, playerId, isOperating, onCancel, onSubmit, playerPositions }) {
    const maxNumLength = useSportJerseyNumLength()
    return (
        <NewTeamForm
        disallowTeamIds={disallowTeamIds}
        changeValues={changeValues}
        changeTeamId={changeTeamId}
        values={values}
        seasonId={seasonId}
        playerId={playerId}
        isOperating={isOperating}
        onCancel={onCancel}
        onSubmit={onSubmit}
        playerPositions={playerPositions}
        maxNumLength={maxNumLength}
      />
    )
}