import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Button, Table } from "reactstrap";

import TitleBar from "@/components/TitleBar";
import EmptyListMessage from "@/components/EmptyListMessage";

import { useGameCategoriesFields } from "../hooks";

import CategoryField from "./CategoryField";

function GameCategories({ value, onChange }) {
  const { categories, appendNewCategory, removeAllCategories } = useGameCategoriesFields({
    value,
    onChange
  });

  return (
    <Fragment>
      <TitleBar title="Game Categories" titleTag="h4" slim>
        <Button type="button" size="sm" color="success" outline onClick={appendNewCategory}>
          + New Category
        </Button>
      </TitleBar>

      <Table striped={categories.length > 0} borderless className={classNames({ "mt-1": categories.length === 0 })}>
        {categories.length > 0 ? (
          <Fragment>
            <thead>
              <tr>
                <th className="py-1">Title</th>
                <th className="narrow py-1">
                  <Button onClick={removeAllCategories} className="delete-button" size="sm" color="danger" outline>
                    Delete All
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {categories.map(({ id, ...category }) => (
                <CategoryField key={id} {...category} />
              ))}
            </tbody>
          </Fragment>
        ) : (
          <tbody>
            <tr>
              <td>
                <EmptyListMessage>
                  No game categories specified.
                  <br />
                  Add a new one.
                </EmptyListMessage>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
    </Fragment>
  );
}

GameCategories.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired
};

export default GameCategories;