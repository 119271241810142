import _isArray from "lodash/isArray";

import {
  createGetService,
  createPutService,
  createPatchService,
  createDeleteService,
  createPostService
} from "./serviceMaker";

export const loadGames = createGetService(
  ({
    seasonId,
    pageNumber,
    pageSize,
    filter: { viewed, flagged, dateFrom, dateTo, query, divisionIds, gameType } = {},
    include
  }) => ({
    url: `/seasons/${seasonId}/games`,
    params: {
      page: { number: pageNumber, size: pageSize },
      filter: {
        viewed: viewed !== "" ? viewed : undefined,
        flagged: flagged !== "" ? flagged : undefined,
        start_time_from: dateFrom && dateFrom !== "" ? `${dateFrom}T00:00:00Z` : undefined,
        start_time_to: dateTo && dateTo !== "" ? `${dateTo}T23:59:00Z` : undefined,
        query: query !== "" ? query : undefined,
        division_id: _isArray(divisionIds) && divisionIds.length > 0 ? divisionIds.join(",") : undefined,
        game_type: gameType
      },
      include
    }
  })
);

export const loadGame = createGetService(({ seasonId, id, include }) => ({
  url: `/seasons/${seasonId}/games/${id}`,
  params: { include }
}));

export const toggleGameViewed = createPutService(({ id, isViewed }) => ({
  url: `/views`,
  method: isViewed ? "PUT" : "DELETE",
  params: { viewable: { type: "games", id } }
}));

export const updateGame = createPatchService(({ id, attributes, seasonId, homeId, visitorId }) => ({
  url: `/seasons/${seasonId}/games/${id}`,
  relationships: {
    season: { data: { type: "seasons", id: seasonId } },
    home: { data: { type: "teams", id: homeId } },
    visitor: { data: { type: "teams", id: visitorId } }
  },
  attributes
}));

export const loadGameGamesheets = createGetService(({ seasonId, gameId }) => ({
  url: `/seasons/${seasonId}/games/${gameId}/gamesheets`
}));

export const loadGameGamesheet = createGetService(({ seasonId, gameId, id }) => ({
  url: `/seasons/${seasonId}/games/${gameId}/gamesheets/${id}`
}));

export const deleteGame = createDeleteService(({ seasonId, id }) => ({
  url: `/seasons/${seasonId}/games/${id}`
}));

export const createPlayerOfTheGame = createPostService(({ seasonId, gameId, attributes }) => ({
  url: `/seasons/${seasonId}/games/${gameId}/player-of-the-game`,
  attributes
}));

export const exportSeasonGames = createPostService(({ seasonId, email, timezone, gameIds }) => ({
  url: `/seasons/${seasonId}/export`,
  headers: {
    "X-EMAIL": email,
    "X-USER-TIME-ZONE": timezone
  },
  data: {
    gameIds,
  }
}));
