import _isArray from "lodash/isArray";
import moment from "moment";

import {
	createGetService,
	createPostService,
	createPatchService,
	createDeleteService,
} from "./serviceMaker";

export const loadScheduledGames = createGetService(
	({
		seasonId,
		pageNumber,
		pageSize,
		filter: {
			viewed,
			flagged,
			scheduledDateFrom,
			scheduledDateTo,
			query,
			divisionIds,
		},
		include,
	}) => ({
		url: `/seasons/${seasonId}/schedule`,
		params: {
			page: { number: pageNumber, size: pageSize },
			filter: {
				viewed: viewed !== "" ? viewed : undefined,
				flagged: flagged !== "" ? flagged : undefined,
				scheduled_start_time_from:
					scheduledDateFrom && scheduledDateFrom !== ""
						? `${scheduledDateFrom}T00:00:00Z`
						: undefined,
				scheduled_start_time_to:
					scheduledDateTo && scheduledDateTo !== ""
						? `${scheduledDateTo}T23:59:59Z`
						: undefined,
				query: query !== "" ? query : undefined,
				division_id:
					_isArray(divisionIds) && divisionIds.length > 0
						? divisionIds.join(",")
						: undefined,
			},
			include,
		},
	})
);

export const loadAllScheduledGames = createGetService(({ seasonId }) => ({
	url: `/seasons/${seasonId}/schedule`,
	params: {
		page: {},
		filter: {},
		include: "",
	},
}));

export const loadScheduledGame = createGetService(
	({ seasonId, gameId, include }) => ({
		url: `/seasons/${seasonId}/schedule/${gameId}`,
		params: { include },
	})
);

export const deleteScheduledGame = createDeleteService(
	({ seasonId, gameId }) => ({
		url: `/seasons/${seasonId}/schedule/${gameId}`,
	})
);

function buildScheduledGameRequestParams({
	visitor,
	home,
	scheduledStartTime,
	timeZoneOffset,
	timeZoneName,
	startTime,
	endTime,
	number,
	location,
	scorekeeper,
	gameType,
	data,
	status,
}) {
	const fullScheduledStartTime =
		scheduledStartTime !== "" && startTime !== ""
			? `${scheduledStartTime}T${startTime}:00Z`
			: undefined;
	const fullScheduledEndTime = (() => {
		if (!fullScheduledStartTime || !endTime) return undefined;
		const start = new Date(fullScheduledStartTime);
		const end = new Date(`${scheduledStartTime}T${endTime}:00Z`);
		if (end < start) {
			end.setDate(end.getDate() + 1);
		}
		return moment(end).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
	})();

	return {
		relationships:
			home.team !== "" ||
			home.division !== "" ||
			visitor.team !== "" ||
			visitor.division !== ""
				? {
						home_team:
							home.team !== ""
								? { data: { type: "teams", id: home.team } }
								: undefined,
						home_division:
							home.division !== ""
								? { data: { type: "divisions", id: home.division } }
								: undefined,
						visitor_team:
							visitor.team !== ""
								? { data: { type: "teams", id: visitor.team } }
								: undefined,
						visitor_division:
							visitor.division !== ""
								? { data: { type: "divisions", id: visitor.division } }
								: undefined,
				  }
				: undefined,
		attributes: {
			scheduledStartTime: fullScheduledStartTime,
			scheduledEndTime: fullScheduledEndTime,
			number,
			location,
			scorekeeper,
			gameType,
			timeZoneOffset,
			timeZoneName,
			data,
			status,
		},
	};
}

export const createScheduledGame = createPostService(
	({ seasonId, values }) => ({
		url: `/seasons/${seasonId}/schedule`,
		...buildScheduledGameRequestParams(values),
	})
);

export const updateScheduledGame = createPatchService(
	({ seasonId, gameId, values }) => ({
		url: `/seasons/${seasonId}/schedule/${gameId}`,
		...buildScheduledGameRequestParams(values),
	})
);
