import React from "react";
import { Link } from "react-router-dom";

function getTeamNameRenderer(seasonId) {
	return ({ value, data: { id } }) => {
		const displayValue = value || <span>------ TBD ------</span>;

		return (
			<Link to={`/seasons/${seasonId}/games/scheduled/${id}/edit`}>
				{displayValue}
			</Link>
		);
	};
}

export default getTeamNameRenderer;
