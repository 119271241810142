import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import useForm from "@/hooks/useForm";

import { getRemoteValidationErrors } from "@/redux/scheduledGameForm";
import { useScheduledGameBroadcasters } from "./useScheduledGameBroadcasters";

import { DEFAULT_VALUES, CONSTRAINTS } from "../constants";

export default function useScheduledGameForm(initialValues = DEFAULT_VALUES) {
	const { BROADCAST_PROVIDERS_LIST } = useScheduledGameBroadcasters();

	const _CONSTRAINTS = { ...CONSTRAINTS };

	const { fields, setErrors, reset, ...rest } = useForm(
		initialValues,
		_CONSTRAINTS
	);

	const remoteValidationErrors = useSelector(getRemoteValidationErrors);

	useEffect(() => {
		if (Object.keys(remoteValidationErrors).length > 0) {
			setErrors(remoteValidationErrors);
		}
	}, [setErrors, remoteValidationErrors]);

	const switchTeams = useCallback(() => {
		fields.home.setValue(fields.visitor.value);
		fields.visitor.setValue(fields.home.value);
		//   fields.data.broadcaster.setValue(fields.data.value);
	}, [fields]);

	const updateBroadcast = useCallback(() => {
		console.log("Update BroadcastProvider", fields);
	}, [fields.broadcaster]);

	const resetScheduledGameForm = () => {
		reset();
	};

	const noTeamsSelected =
		fields.visitor.value.division === "" && fields.home.value.division === "";

	return {
		...rest,
		fields,
		noTeamsSelected,
		BROADCAST_PROVIDERS_LIST,
		switchTeams,
		updateBroadcast,
		reset: resetScheduledGameForm,
	};
}
