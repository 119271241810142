import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	getIsLoaded,
	getIsLoading,
	getFields,
	loadingRoutine,
} from "@/redux/scheduledGameForm";
import { getUrlSearchParamsInfo } from "@/redux/scheduledGamesFilter/selectors";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

export default function useScheduledGameFormLoading(gameId) {
	const dispatch = useDispatch();

	const { seasonId } = useCurrentSeasonContext();
	const isLoaded = useSelector(getIsLoaded);
	const isLoading = useSelector(getIsLoading);
	const initialValues = useSelector(getFields);
	const urlSearchParamsInfo = useSelector(getUrlSearchParamsInfo);

	const append =
		seasonId === urlSearchParamsInfo.seasonId
			? urlSearchParamsInfo.urlSearchParams
			: "";
	const scheduledGamesLink = `/seasons/${seasonId}/games/scheduled${append}`;

	useEffect(() => {
		if (!isLoaded && !isLoading) {
			dispatch(loadingRoutine({ seasonId, gameId }));
		}
	}, [dispatch, isLoaded, isLoading, seasonId, gameId]);

	return {
		isLoaded,
		isLoading,
		initialValues,
		scheduledGamesLink,
	};
}
