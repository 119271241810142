import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
	getIsSubmitting,
	getOneGameData,
	submittingRoutine,
} from "@/redux/scheduledGameForm";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import { useHistory } from "react-router";

export default function useScheduledGameFormSubmitting({ values, gameId }) {
	const dispatch = useDispatch();
	const { seasonId } = useCurrentSeasonContext();
	const history = useHistory();
	const [clear, setClear] = useState(0);

	const {
		broadcaster,
		timeZone,
		offset,
		home,
		visitor,
		visitorLabel,
		homeLabel,
		...remaining
	} = values;

	const isTBDTeam = (team) => team.team && team.team.startsWith("TBD - ");

	const getTeamData = (team) =>
		isTBDTeam(team) ? { division: team.division, team: "" } : team;

	const submitValues = {
		...remaining,
		timeZoneOffset: offset,
		timeZoneName: timeZone,
		home: getTeamData(home),
		visitor: getTeamData(visitor),
		data: {
			broadcaster,
			home_label: isTBDTeam(home) ? homeLabel : "",
			visitor_label:isTBDTeam(visitor) ? visitorLabel : "",
		},
	};

	const isSubmitting = useSelector(getIsSubmitting);

	const submit = useCallback(
		({ goBack = true }) => {
			dispatch(
				submittingRoutine({
					gameId,
					seasonId,
					values: submitValues,
					goBack: goBack,
					history,
				})
			);
		},
		[dispatch, submitValues, gameId, seasonId]
	);

	return {
		isSubmitting,
		submit,
		clear,
		setClear,
	};
}
