import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { getMeta, actions, submittingRoutine } from "@/redux/cloneSeasonForm";
import { toHockeyYear } from "@/components/HockeyYearField/DateUtilities";

export const defaultState = {
	originalSeason: {
		id: "",
	},
	newSeason: {
		title: "",
		statsYear: "",
		startDate: "",
		endDate: "",
	},
	options: {
		ipadKeys: true,
		divisions: true,
		teams: true,
		teamRosters: false,
		seasonRoster: false,
		referees: true,
		prototeams: false,
	},
};

// assumes season is loaded
export function useCloneSeasonForm() {
	// state
	const { season, seasonLeagueId } = useCurrentSeasonContext();
	const history = useHistory();
	const dispatch = useDispatch();
	const [increment, setIncrement] = useState(false);
	const [values, setValues] = useState(defaultState);
	const { isSubmitting } = useSelector(getMeta);
	// -- end state

	const [startDate, endDate] = increment
		? addYearToDates(season.startDate, season.endDate)
		: [season.startDate, season.endDate];
	const updatedStatsYear = increment
		? getNextStatsYear(season.statsYear)
		: season.statsYear;

	// selectors
	const newSeasonTitle = `${season.title} Copy`;
	const newStatsYear = updatedStatsYear
		? updatedStatsYear
		: toHockeyYear(startDate, endDate);
	const newStartDate = startDate;
	const newEndDate = endDate;
	const cloneIpadKeys = !!values.options.ipadKeys;
	const cloneDivisions = !!values.options.divisions;
	const cloneTeams = !!values.options.teams;
	const cloneTeamRosters = !!values.options.teamRosters;
	const cloneSeasonRoster = !!values.options.seasonRoster;
	const cloneReferees = !!values.options.referees;
	const clonePrototeams = !!values.options.prototeams;
	// -- end selectors

	// reducers
	const setOptionValue = (key, val) => {
		setValues((old) => ({
			...old,
			options: {
				...old.options,
				[key]: val,
			},
		}));
	};

	const setIpadKeysValue = (val) => {
		setOptionValue("ipadKeys", val);
	};

	const setDivisionsValue = (val) => {
		setOptionValue("divisions", val);
		if (val === false) {
			setOptionValue("teams", false);
			setOptionValue("teamRosters", false);
			setOptionValue("prototeams", false);
		}
	};

	const setTeamsValue = (val) => {
		setOptionValue("teams", val);
		if (val === true) {
			setOptionValue("divisions", true);
		} else {
			setOptionValue("teamRosters", false);
			setOptionValue("prototeams", false);
		}
	};

	const setTeamRostersValue = (val) => {
		setOptionValue("teamRosters", val);
		if (val === true) {
			setOptionValue("divisions", true);
			setOptionValue("teams", true);
		}
	};

	const setSeasonRosterValue = (val) => {
		setOptionValue("seasonRoster", val);
	};

	const setRefereesValue = (val) => {
		setOptionValue("referees", val);
	};

	const setPrototeamsValue = (val) => {
		if (values.options.teams === true) {
			setOptionValue("prototeams", val);
		}
	};

	// -- end reducers

	// useEffects
	useEffect(() => {
		setValues((old) => {
			return {
				...old,
				newSeason: {
					...old.newSeason,
					title: newSeasonTitle,
					statsYear: newStatsYear,
					startDate: newStartDate,
					endDate: newEndDate,
				},
			};
		});
	}, [setValues, newSeasonTitle, newStartDate, newEndDate, newStatsYear]);

	useEffect(() => {
		setValues((old) => {
			return {
				...old,
				originalSeason: {
					...old.originalSeason,
					...season,
				},
			};
		});
	}, [setValues, season]);
	// -- end useEffects

	// callbacks
	const submit = useCallback(() => {
		dispatch(
			submittingRoutine({
				data: formatPayload(values),
				leagueId: seasonLeagueId,
			})
		);
	}, [dispatch, submittingRoutine, seasonLeagueId, values]);

	const cancel = useCallback(() => {
		setValues(defaultState);
		dispatch(actions.clear());
		history.push(`/leagues/${seasonLeagueId}/seasons`);
	}, [dispatch, actions, history, seasonLeagueId]);
	// -- end callbacks

	return {
		season,
		seasonLeagueId,
		isSubmitting,
		newSeasonTitle,
		newStartDate,
		newEndDate,
		newStatsYear,
		cloneIpadKeys,
		cloneDivisions,
		cloneTeams,
		cloneTeamRosters,
		cloneSeasonRoster,
		cloneReferees,
		clonePrototeams,
		setIpadKeysValue,
		setDivisionsValue,
		setTeamsValue,
		setTeamRostersValue,
		setSeasonRosterValue,
		setRefereesValue,
		setPrototeamsValue,
		submit,
		cancel,
		increment,
		setIncrement,
	};
}

const formatPayload = (values) => {
	return {
		old: {
			id: Number(values.originalSeason.id),
		},
		new: {
			title: values.newSeason.title,
			statsYear: values.newSeason.statsYear,
			startDate: values.newSeason.startDate,
			endDate: values.newSeason.endDate,
		},
		options: values.options,
	};
};

function getNextStatsYear(yearStr) {
	if (!yearStr) {
		return "";
	}

	if (!/^\d{4}(-\d{4})?$/.test(yearStr)) {
		throw new Error("Invalid year format. Please use YYYY or YYYY-YYYY");
	}

	if (yearStr.length === 4) {
		const year = parseInt(yearStr);
		// If year is 2025 or greater, return the year as is
		if (year >= 2026) {
			return yearStr;
		}
		const nextYear = year + 1;
		return `${nextYear}`;
	} else {
		const [startYear, endYear] = yearStr.split("-").map(Number);
		if (endYear !== startYear + 1) {
			throw new Error("Invalid academic year range");
		}
		// If we're already at 2025-2026, return as is
		if (yearStr === "2025-2026") {
			return yearStr;
		}
		const newStartYear = startYear + 1;
		const newEndYear = endYear + 1;
		return `${newStartYear}-${newEndYear}`;
	}
}

function addYearToDates(start, end) {
	const [startYear, startMonth, startDay] = start.split("-").map(Number);
	const [endYear, endMonth, endDay] = end.split("-").map(Number);

	const formatDate = (year, month, day) =>
		`${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;

	// Determine year adjustments based on a simple rule:
	// If the year is 2026, keep it as is; otherwise, add 1 year
	const adjustYear = (year) => (year === 2026 ? year : year + 1);

	return [
		formatDate(adjustYear(startYear), startMonth, startDay),
		formatDate(adjustYear(endYear), endMonth, endDay),
	];
}
