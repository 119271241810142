import * as React from "react";
import useSportJerseyNumLength from "@/hooks/useSportJerseyNumLength";
import TeamPlayerForm from "./TeamPlayerForm";

export default function TeamPlayerFormWrapper({isOperating, playersRoster, playerId, onSubmit, onDelete, rosterLocked, playerPositions, teamPlayer}) {
    const maxNumLength = useSportJerseyNumLength();
    return (
        <TeamPlayerForm
        isOperating={isOperating}
        playersRoster={playersRoster}
        playerId={playerId}
        onSubmit={onSubmit}
        onDelete={onDelete}
        rosterLocked={rosterLocked}
        playerPositions={playerPositions}
        maxNumLength={maxNumLength}
        {...teamPlayer}
      />
    )
}