import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

function SelectInput({
	value,
	onChange,
	id,
	name,
	placeholder,
	collection,
	allowBlank,
	disabled,
	disallowValue,
	invalid,
}) {
	return (
		<Input
			invalid={invalid}
			type="select"
			className="custom-select"
			id={id}
			name={name}
			onChange={onChange}
			value={value || ""}
			disabled={disabled}
		>
			<option value="" disabled={!allowBlank}>
				{placeholder}
			</option>
			{placeholder === "TBD" && (
				<option value="TBD - Custom Label">TBD - Custom Label</option>
			)}
			{collection.map(({ id, title }) => (
				<option key={id} value={id} disabled={id === disallowValue}>
					{title}
				</option>
			))}
		</Input>
	);
}

SelectInput.propTypes = {
	value: PropTypes.string,
	disallowValue: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	collection: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		})
	),
	allowBlank: PropTypes.bool,
	invalid: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
};

SelectInput.defaultProps = {
	disallowValue: "",
	disabled: false,
	allowBlank: false,
	invalid: false,
	placeholder: "Select option…",
	collection: [],
	onChange() {},
};

export default SelectInput;
