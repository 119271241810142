import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { FormGroup, Label, Input, Button, FormFeedback } from "reactstrap";

import DashboardForm from "@/components/DashboardForm";
import ContentSection from "@/components/ContentSection";
import FormItem from "@/components/FormItem";
import TitleBar from "@/components/TitleBar";
import SeasonTeamsSelect from "@/components/SeasonTeamsSelect";
import DashboardFormFooter from "@/components/DashboardFormFooter";

class PlayerTeam extends PureComponent {
  static propTypes = {
    seasonId: PropTypes.string.isRequired,
    playerId: PropTypes.string.isRequired,
    isOperating: PropTypes.bool,
    values: PropTypes.shape({
      teamId: PropTypes.string,
      jersey: PropTypes.string,
      affiliated: PropTypes.bool,
      position: PropTypes.string,
      duty: PropTypes.string
    }),
    disallowTeamIds: PropTypes.arrayOf(PropTypes.string),
    changeValues: PropTypes.func,
    changeTeamId: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    playerPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    maxNumLength: PropTypes.number
  };

  static defaultProps = {
    isOperating: false,
    values: {
      teamId: "",
      jersey: "",
      affiliated: false,
      duty: ""
    },
    disallowTeamIds: [],
    playerPositions: [],
    changeValues() {},
    changeTeamId() {},
    onCancel() {},
    onSubmit() {},
    maxNumLength: 2
  };

  validateJerseyNumber = (value) => {
    const { maxNumLength } = this.props
    const number = Number(value);
    const length = value.length;
    return length === 0 || (length <= maxNumLength && Number.isInteger(number) && number >= 0);
  };

  handleInputChange = ({ target }) => {
    const { changeValues } = this.props;

    const value = target.name === "affiliated" ? target.value === "true" : target.value;

    if (target.name === 'jersey') {
      const isValidNumber = this.validateJerseyNumber(value);
      target.setCustomValidity(isValidNumber ? '' : 'Invalid jersey number');
    }

    changeValues({ [target.name]: value });
  };

  handleTeamChange = teamId => {
    const { changeTeamId } = this.props;

    changeTeamId({ id: teamId });
  };

  handleSubmit = () => {
    const { values, onSubmit } = this.props;

    onSubmit(values);
  };

  render() {
    const {
      seasonId,
      values: { teamId, jersey, affiliated, position, duty },
      isOperating,
      onCancel,
      disallowTeamIds,
      playerPositions,
      maxNumLength 
    } = this.props;

    return (
      <DashboardForm className="player-form mt-5" isOperating={isOperating}>
        <TitleBar title="Add player to team" titleTag="h3" />
        <ContentSection>
          <FormGroup>
            <SeasonTeamsSelect
              disallowIds={disallowTeamIds}
              seasonId={seasonId}
              defaultValue={teamId}
              onChange={this.handleTeamChange}
            />
          </FormGroup>

          <FormItem>
            <FormGroup className="col-md-3">
              <Label for="jersey">Jersey</Label>
              <Input
                id="teamPlayerJersey"
                name="jersey"
                defaultValue={jersey}
                placeholder="Jersey"
                disabled={teamId === ""}
                onChange={this.handleInputChange}
                invalid={jersey && !this.validateJerseyNumber(jersey)}
                maxLength={maxNumLength}
                pattern="[0-9]*"
              />
                <FormFeedback>
              {`Jersey number must be between 0-${Math.pow(10, maxNumLength) - 1}`}
            </FormFeedback>
            </FormGroup>
            <FormGroup className="col-md-3">
              <Label for="duty">Designation</Label>
              <Input
                type="select"
                id="teamPlayerDuty"
                name="duty"
                disabled={teamId === ""}
                defaultValue={duty}
                onChange={this.handleInputChange}
              >
                <option value="">No designation</option>
                <option value="captain">Captain</option>
                <option value="alternate_captain">Alternate Captain</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="position">Position</Label>
              <Input
                type="select"
                id="teamPlayerPosition"
                name="position"
                disabled={teamId === ""}
                defaultValue={position}
                onChange={this.handleInputChange}
              >
                <option value="" disabled>
                  Select position
                </option>
                {playerPositions.map(position => (
                  <option key={position.key} value={position.key}>
                    {position.value}
                  </option>
                ))}
              </Input>
            </FormGroup>

            <FormGroup className="col-md-3">
              <Label for="affiliated">Status</Label>
              <Input
                type="select"
                id="teamPlayerStatus"
                name="affiliated"
                disabled={teamId === ""}
                defaultValue={affiliated}
                onChange={this.handleInputChange}
              >
                <option value={false}>Regular</option>
                <option value={true}>Affiliated</option>
              </Input>
            </FormGroup>
          </FormItem>

          <DashboardFormFooter className="small-margin">
            <div className="dashboard-form-footer__primary">
              <Button outline color="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button color="success" disabled={teamId === ""} onClick={this.handleSubmit}>
                Add
              </Button>
            </div>
          </DashboardFormFooter>
        </ContentSection>
      </DashboardForm>
    );
  }
}

export default PlayerTeam;
