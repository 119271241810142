import _snakeCase from "lodash/snakeCase";
import { useEffect } from "react";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";

import {
	selectedIdsState,
	scheduledGamesState,
	getGames,
	scheduledGamesMetaState,
} from "./state";
import {
	startDateFilterState,
	endDateFilterState,
	divisionsFilterState,
	queryStringFilterState,
	gameTypeFilterState,
} from "../Filter/state";

export function useList({ seasonId, seasonTitle }) {
	const scheduledGamesData = useRecoilValue(scheduledGamesState({ seasonId }));
	const [selectedIds, setSelectedIds] = useRecoilState(selectedIdsState);
	const setScheduledGamesMeta = useSetRecoilState(scheduledGamesMetaState);

	useEffect(() => {
		if ("meta" in scheduledGamesData) {
			setScheduledGamesMeta(scheduledGamesData.meta);
		}
	}, [scheduledGamesData]);

	const filter = {};
	filter.startDate = useRecoilValue(startDateFilterState);
	filter.endDate = useRecoilValue(endDateFilterState);
	filter.divisions = useRecoilValue(divisionsFilterState);
	filter.queryString = useRecoilValue(queryStringFilterState);
	filter.gameType = useRecoilValue(gameTypeFilterState);

	const formatDate = (dateStr) => {
		const date = new Date(dateStr);
		return `${date.getFullYear()}-${(date.getMonth() + 1)
			.toString()
			.padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
	};

	const formatTime = (timeStr) => {
		const found = timeStr.match(/^(\d\d?):(\d\d) (AM|PM)$/);
		if (!found || found.length !== 4) {
			return "";
		}

		switch (found[3]) {
			case "AM": {
				const hour = (Number(found[1]) % 12).toString().padStart(2, "0");
				const minute = found[2].toString().padStart(2, "0");
				return `${hour}:${minute}`;
			}
			case "PM": {
				const hour = ((Number(found[1]) % 12) + 12).toString();
				const minute = found[2].toString().padStart(2, "0");
				return `${hour}:${minute}`;
			}
			default:
				return "";
		}
	};

	const csvExportParams = {
		skipHeader: true,
		fileName: `${seasonTitle} - Scheduled Games.csv`,
		customHeader:
			"ID,Date,Time,End Time,Time Zone,Visitor Division,Visitor Team,Home Division,Home Team,Location,Game Number,Game Type,Scorekeeper Name,Scorekeeper Phone,Broadcast Provider",
		columnKeys: [
			"id",
			"scheduledDate",
			"scheduledTime",
			"scheduledEndTime",
			"timeZone",
			"visitorDivision",
			"visitorTeam",
			"homeDivision",
			"homeTeam",
			"location",
			"number",
			"gameType",
			"scorekeeperName",
			"scorekeeperPhone",
			"broadcastProvider",
		],
		processCellCallback: (params) => {
			switch (params.column.colId) {
				case "scheduledDate":
					return formatDate(params.value);
				case "scheduledTime":
					return formatTime(params.value);
				case "scheduledEndTime":
					return formatTime(params.value);
				case "gameType":
					return _snakeCase(params.value);
				default:
					return params.value;
			}
		},
		onlySelected: selectedIds.length > 0,
	};

	const bigExport = async () => {
		const gameData = await getGames({
			...filter,
			currentPage: 1,
			API_PAGE_SIZE: 100000,
			seasonId,
		});

		const rows = gameData.games.map((game) => {
			return csvExportParams.columnKeys
				.map((key) => {
					const value = csvExportParams.processCellCallback({
						column: { colId: key },
						value: game[key],
					});
					return `"${value}"`;
				})
				.join(",");
		});

		rows.splice(0, 0, csvExportParams.customHeader);

		const content = rows.reduce((collector, row) => {
			return collector + row + "\r\n";
		}, "");

		const hiddenLink = document.createElement("a");
		hiddenLink.href =
			"data:text/csv;charset=utf-8," + encodeURIComponent(content);
		hiddenLink.download = csvExportParams.fileName;
		hiddenLink.click();
	};

	return {
		scheduledGames: scheduledGamesData.games,
		selected: selectedIds,
		setSelected: setSelectedIds,
		csvExportParams,
		bigExport,
	};
}
