import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";

import { Input, Label } from "reactstrap";

import { getHockeyYearOptions} from "./DateUtilities";

function HockeyYearField(props) {
  const options = getHockeyYearOptions();

  const { value, setValue } = props;

  const onChange = useCallback(value => setValue(value), [setValue]);

  return (
    <Fragment>
      <Label for="seasonHokeyYear">Stats Year</Label>
      <Input id="seasonHokeyYear" type="select" value={value ? value : "2024-2025"} onChange={(e) => onChange(e.target.value)}>
        {options.map(hockeyYear => (
          <option value={hockeyYear} key={hockeyYear}>
            {hockeyYear}
          </option>
        ))}
      </Input>
    </Fragment>
  );
}

HockeyYearField.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func
};

export default HockeyYearField;
