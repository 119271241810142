import { handleActions, combineActions } from "redux-actions";

import { submittingRoutine, deletingRoutine } from "@/redux/seasonForm";

import { loadingRoutine } from "./routines";

import actions from "./actions";

const initialState = {
  isLoading: false,
  isLoaded: false,
  isForbidden: false,
  isFailed: false,
  ids: [],
  startDate: '',
  endDate: '',
  statsYear: '',
  data: {
    active: {},
    archived: {}
  },
  totalCount: 0
};

function reduceSeasons(seasons, extra) {
  return seasons.reduce(
    (result, { id, title, startDate, endDate, statsYear }) => ({
      ...result,
      [id]: { title, startDate, endDate, statsYear, ...extra }
    }),
    {}
  );
}

function reduceLoadingRequest(state) {
  return { ...state, isLoading: true };
}

function reduceLoadingSuccess(state, { payload }) {
  const { archived, active, ids, totalCount } = payload;

  const activeData = reduceSeasons(active, { isArchiving: false });
  const archivedData = reduceSeasons(archived, { isUnarchiving: false });

  const data = { active: activeData, archived: archivedData };

  return { ...state, isLoaded: true, data, ids, totalCount };
}

function reduceLoadingFailure(state, { payload: { responseStatus } }) {
  return { ...state, isFailed: true, isForbidden: responseStatus === 401 };
}

function reduceLoadingFulfill(state) {
  return { ...state, isLoading: false };
}

export default handleActions(
  {
    [loadingRoutine.REQUEST]: reduceLoadingRequest,
    [loadingRoutine.SUCCESS]: reduceLoadingSuccess,
    [loadingRoutine.FAILURE]: reduceLoadingFailure,
    [loadingRoutine.FULFILL]: reduceLoadingFulfill,
    [combineActions(
      actions.clear,
      submittingRoutine.SUCCESS,
      deletingRoutine.SUCCESS,
    )]: () => ({ ...initialState })
  },
  initialState
);
