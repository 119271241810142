import React, { Fragment } from "react";

import { Button, Table } from "reactstrap";
import { FilePicker } from "react-file-picker";
import { Link } from "react-router-dom";
import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import PageLoader from "@/components/PageLoader";
import TitleBar from "@/components/TitleBar";
import DashboardForm from "@/components/DashboardForm";
import ContentSection from "@/components/ContentSection";
import DashboardFormFooter from "@/components/DashboardFormFooter";

import { useScheduledGameBroadcasters } from "@/components/ScheduledGameForm/hooks/useScheduledGameBroadcasters";

import {
	useScheduledGamesCSVImportWizardParser,
	useScheduledGamesCSVImportWizardTemplate,
} from "./hooks";

import InvalidRow from "./InvalidRow";

function Parser() {
	const {
		error,
		isValidationFailed,
		isFailed,
		isSuccess,
		isParsing,
		parseFile,
		countValidationErrors,
		invalidRows,
		countInsert,
		countUpdate,
		reset,
		beginImport,
	} = useScheduledGamesCSVImportWizardParser();

	const { season } = useCurrentSeasonContext();

	const downloadTemplate = useScheduledGamesCSVImportWizardTemplate();

	const { BROADCAST_PROVIDER_VALUES } = useScheduledGameBroadcasters();

	const broadcastList = BROADCAST_PROVIDER_VALUES.filter((val) => {
		return !!val;
	})
		.map((val) => {
			return <code key={val}>{val}</code>;
		})
		.reduce((list, val) => {
			if (!list) {
				return [val];
			} else {
				return [...list, ", ", val];
			}
		}, null);

	const confirmationMessage = (() => {
		const insertPart = (
			<span key="insertPart">
				<strong>{countInsert}</strong> scheduled{" "}
				{countInsert === 1 ? "game" : "games"} will be imported
			</span>
		);
		const longUpdatePart = (
			<span key="updatePart">
				<strong>{countUpdate}</strong> scheduled{" "}
				{countUpdate === 1 ? "game" : "games"} will be updated
			</span>
		);
		const shortUpdatePart = (
			<span key="updatePart">
				<strong>{countUpdate}</strong> will be updated
			</span>
		);
		const seasonPart = (
			<span key="seasonPart">
				{" "}
				in the <strong>{season.title}</strong> season.
			</span>
		);
		const andPart = <span key="andPart"> and </span>;

		let msg = <></>;
		if (countInsert > 0 && countUpdate > 0) {
			msg = [insertPart, andPart, shortUpdatePart, seasonPart];
		} else if (countInsert > 0 && countUpdate == 0) {
			msg = [insertPart, seasonPart];
		} else if (countInsert == 0 && countUpdate > 0) {
			msg = [longUpdatePart, seasonPart];
		} else {
			msg = "Nothing to import"; // should never happen
		}

		return msg;
	})();

	if (isParsing) {
		return <PageLoader isLoading={true} />;
	} else if (isSuccess && !isValidationFailed) {
		return (
			<Fragment>
				<TitleBar title="Confirm Import" />

				<div className="scheduled-games-csv-import__confirmation__text">
					<p>{confirmationMessage}</p>
					<p>Would you like to continue?</p>
				</div>

				<DashboardFormFooter>
					<div className="dashboard-form-footer__secondary">
						<Button type="button" color="secondary" outline onClick={reset}>
							Cancel
						</Button>
					</div>

					<div className="dashboard-form-footer__primary">
						<Button type="button" color="success" onClick={beginImport}>
							Begin Import
						</Button>
					</div>
				</DashboardFormFooter>
			</Fragment>
		);
	}

	return (
		<DashboardForm>
			{!isValidationFailed ? (
				<Fragment>
					<TitleBar title="Select CSV File for Import">
						<Button
							color="primary"
							outline
							onClick={downloadTemplate}
							size="sm"
						>
							Download CSV template
						</Button>
					</TitleBar>

					{isFailed && error && <p className="text-danger">{error}</p>}

					<ContentSection title="Expected CSV File Layout">
						<div className="scheduled-games-csv-import__overflow-x-scroll">
							<Table bordered size="sm">
								<thead>
									<tr>
										<th>
											Date <span className="text-danger">*</span>
										</th>
										<th>
											Time <span className="text-danger">*</span>
										</th>
										<th>End Time</th>
										<th>
											Time Zone <span className="text-danger">*</span>
										</th>
										<th>Visitor Division</th>
										<th>Visitor Team</th>
										<th>
											Home Division <span className="text-danger">*</span>
										</th>
										<th>Home Team</th>
										<th>Location</th>
										<th>
											Game Number <span className="text-danger">*</span>
										</th>
										<th>
											Game Type <span className="text-danger">*</span>
										</th>
										<th>Scorekeeper Name</th>
										<th>Scorekeeper Phone</th>
										<th>Broadcast Provider</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>2024-04-21</td>
										<td>10:00</td>
										<td>11:30</td>
										<td>America/Toronto</td>
										<td>Bantam AA</td>
										<td>Apple Valley Hayes</td>
										<td>Eastview Bantam AA</td>
										<td>Lakeville North Bantam AA</td>
										<td>Mars Arena</td>
										<td>75</td>
										<td>regular_season</td>
										<td>John Doe</td>
										<td>416-555-0122</td>
										<td>LIVEBARN</td>
									</tr>
									<tr>
										<td>2024-05-17</td>
										<td>19:00</td>
										<td>20:15</td>
										<td>America/New_York</td>
										<td>Bantam AA</td>
										<td>TBD-Winner of Pool A</td>
										<td>Woodbury Bantam AA</td>
										<td>TBD-Winner of Game 67</td>
										<td />
										<td>76</td>
										<td>tournament</td>
										<td />
										<td />
										<td>GAMEON</td>
									</tr>
								</tbody>
							</Table>
						</div>

						<div className="mt-5">
							<h5>Requirements and limitations</h5>

							<ul>
								<li>
									Fields marked with <span className="text-danger">*</span> are
									required. Others are optional.
								</li>
								<li>
									Expected date format is <code>YYYY-MM-DD</code>
								</li>
								<li>
									Time is allowed to be expressed in 12-hour clock as well as
									24-hour clock format
								</li>
								<li>
									Valid game types: <code>exhibition</code>,{" "}
									<code>playoff</code>, <code>regular_season</code>, and{" "}
									<code>tournament</code>
								</li>
								<li>
									To add a custom TBD team name, prefix the team's name with
									"TBD-"
									<ul>
										<li>Example: TBD-Winner of Pool A</li>
									</ul>
								</li>
								<li>Valid broadcast providers: {broadcastList}</li>
								<li>Division and team titles are not case sensitive</li>
								<li>
									Existing games can be updated via CSV import by inputting a
									GameSheet ID that matches an existing scheduled game within
									the same season. The game being imported via CSV will replace
									the existing game.
								</li>
								<li>
									We support a wide range of global time zones, including but
									not limited to:
									<ul>
										<li>America/Halifax</li>
										<li>America/New_York</li>
										<li>America/Chicago</li>
										<li>America/Denver</li>
										<li>America/Vancouver</li>
									</ul>
									The complete list of valid Time Zones can be found{" "}
									<a
										href="https://help.gamesheet.app/article/119-time-zone"
										target="_blank"
										rel="noopener noreferrer"
									>
										here.
									</a>
								</li>
							</ul>
						</div>
					</ContentSection>
				</Fragment>
			) : (
				<Fragment>
					<div className="scheduled-games-csv-import__error__text">
						<p>
							{countValidationErrors} validation error
							{countValidationErrors == 1 ? " was" : "s were"} found in the CSV.
							Please fix and try again.
						</p>
					</div>

					<div className="scheduled-games-csv-import__overflow-x-scroll">
						<Table
							striped
							borderless
							className="scheduled-games-csv-import__error__table"
							size="sm"
						>
							<thead>
								<tr>
									<th>Line #</th>
									<th>ID</th>
									<th>Date</th>
									<th>Time</th>
									<th>End Time</th>
									<th>Time Zone</th>
									<th>Visitor Division</th>
									<th>Visitor Team</th>
									<th>Home Division</th>
									<th>Home Team</th>
									<th>Location</th>
									<th>Game Number</th>
									<th>Game Type</th>
									<th>Scorekeeper Name</th>
									<th>Scorekeeper Phone</th>
									<th>Broadcast Provider</th>
								</tr>
							</thead>
							<tbody>
								{invalidRows.map(({ lineNumber, record, validationErrors }) => (
									<InvalidRow
										key={lineNumber}
										lineNumber={lineNumber}
										record={record}
										errors={validationErrors}
									/>
								))}
							</tbody>
						</Table>
					</div>
				</Fragment>
			)}

			<DashboardFormFooter>
				<div className="dashboard-form-footer__primary">
					<FilePicker onChange={parseFile}>
						<Button type="button" color="success">
							{isFailed || isValidationFailed
								? "Re-select file"
								: "Select file"}
						</Button>
					</FilePicker>
				</div>
			</DashboardFormFooter>
		</DashboardForm>
	);
}

export default Parser;
