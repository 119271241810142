import React, { Fragment } from "react";
import PropTypes from "prop-types";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import ProtectedLayout from "@/layouts/ProtectedLayout";
import Breadcrumbs from "@/components/Breadcrumbs";
import TitleBar from "@/components/TitleBar";
import Head from "@/components/Head";
import PageLoader from "@/components/PageLoader";

import ScheduledGameForm, {
	useScheduledGameFormClearing,
	useScheduledGameFormLoading,
} from "@/components/ScheduledGameForm";

import {
	useTeamPickerFieldLoading,
	useTeamPickerFieldClearing,
} from "@/components/TeamPickerField";

function EditScheduledGame({ match: { params } }) {
	const { gameId } = params;

	const {
		seasonIsLoading,
		seasonIsLoaded,
		seasonIsForbidden,
		seasonBreadcrumbs,
		seasonId,
	} = useCurrentSeasonContext();

	const { isLoaded: isTeamListLoaded, isLoading: isTeamListLoading } =
		useTeamPickerFieldLoading();

	useTeamPickerFieldClearing();

	useScheduledGameFormClearing();

	const { isLoaded, isLoading, initialValues, scheduledGamesLink } =
		useScheduledGameFormLoading(gameId);

	return (
		<ProtectedLayout condition={!seasonIsForbidden}>
			<PageLoader
				isLoading={seasonIsLoading || isTeamListLoading}
				isLoaded={seasonIsLoaded && isTeamListLoaded}
			>
				{seasonIsLoaded && (
					<Fragment>
						<Head title="Edit Scheduled Game" />
						<Breadcrumbs
							items={
								seasonBreadcrumbs && [
									...seasonBreadcrumbs,
									{
										title: "Scheduled Games",
										url: scheduledGamesLink,
									},
								]
							}
						/>

						<TitleBar title="Edit Scheduled Game" />
					</Fragment>
				)}
			</PageLoader>

			{seasonIsLoaded &&
				isTeamListLoaded &&
				(isLoaded ? (
					<ScheduledGameForm
						gameId={gameId}
						initialValues={initialValues}
						seasonId={seasonId}
					/>
				) : (
					<PageLoader isLoaded={isLoaded} isLoading={isLoading} />
				))}
		</ProtectedLayout>
	);
}

EditScheduledGame.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({ gameId: PropTypes.string.isRequired }).isRequired,
	}).isRequired,
};

export default EditScheduledGame;
