import React, { Fragment, useEffect } from "react";

import ReactDateInput from "react-datetime";

function ScheduledDateInput({ date, id }) {
	const onChange = (e) => {
		if (!e || typeof e === "string") return;
		const formattedDate = e.format("YYYY-MM-DD");
		date.setValue(formattedDate);
	};

	return (
		<Fragment>
			<ReactDateInput
				id={id}
				dateFormat="YYYY-MM-DD"
				timeFormat={false}
				inputProps={{
					// className: classNames("form-control", {
					// 	"is-invalid": date.isDirty && date.isInvalid,
					// }),
					onChange: () => {},
					placeholder: "YYYY-MM-DD",
				}}
				closeOnSelect={true}
				onChange={onChange}
				value={date.value}
			/>
		</Fragment>
	);
}

export default ScheduledDateInput;
