export default function validateType({ jerseyNumber }) {
  const number = Number(jerseyNumber);
  const length = jerseyNumber.length;

  if (
    length > 0 &&
    (length > 2 || !(Number.isInteger(number) && number >= 0))
  ) {
    return [{ field: "jerseyNumber", error: "invalid" }];
  }

  return [];
}

export function makeNumberValidator(maxJerseyNumberLength) {
  return function validateNumber({ jerseyNumber }) {
    const number = Number(jerseyNumber);
    const length = jerseyNumber.length;

    if (
      length > 0 &&
      (length > maxJerseyNumberLength || !(Number.isInteger(number) && number >= 0))
    ) {
      return [{ field: "jerseyNumber", error: "invalid" }];
    }

    return [];
  };
}