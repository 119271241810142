import default_validations from "./validations";
import { makePositionValidator } from "./validations/validatePosition";
import { makeNumberValidator } from "./validations/validateJerseyNumber";

function validateRecord(record, validationOptions, positionValidator, validateJerseyNumber) {
  const validations = [ ...default_validations ];
  validations.push(positionValidator);
  validations.push(validateJerseyNumber);

  return validations.reduce((allErrors, validation) => {
    const errors = validation(record, validationOptions);
    return errors.length > 0 ? [...allErrors, ...errors] : allErrors;
  }, []);
}

export default function validate(records, validationOptions, playerPositions, coachPositions, maxJerseyNumberLength) {
  const positionValidator = makePositionValidator(playerPositions, coachPositions);
  const validateJerseyNumber = makeNumberValidator(maxJerseyNumberLength);

  return records.map(record => ({
    ...record,
    validationErrors: validateRecord(record, validationOptions, positionValidator, validateJerseyNumber)
  }));
}
