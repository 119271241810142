import { config } from "./config";

export const unleashConfig = {
	url: config.UNLEASH_CONFIG.url,
	clientKey: config.UNLEASH_CONFIG.clientKey,
	appName: config.UNLEASH_CONFIG.appName,
	environment: config.UNLEASH_CONFIG.environment,
	refreshInterval: config.UNLEASH_CONFIG.refreshInterval,
    context: {
      userId: null,
    },
};
