import React from "react";
import PropTypes from "prop-types";
import { Col, FormGroup, Input } from "reactstrap";
import FormItem from "@/components/FormItem";

import JerseyInput from "./components/JerseyInput";
import PositionInput from "./components/PositionInput";
import StatusInput from "./components/StatusInput";

import FullName from "@/components/FullName";

function LineupInput(props) {
  const { team, player, stripe, refreshJerseyValidity, onChange, maxNumLength } = props;

  return (
    <FormItem className={`${stripe ? "stripe" : ""}`}>
      <Col className="col-md-5 d-flex flex-column justify-content-center">
        <FullName firstName={player.firstName} lastName={player.lastName} />
      </Col>

      <JerseyInput player={player} team={team} refreshJerseyValidity={refreshJerseyValidity} onChange={onChange} maxNumLength={maxNumLength} />

      <PositionInput team={team} player={player} onChange={onChange} />

      <FormGroup className="col-md-2">
        <Input type="select" name="duty" value={player.duty} onChange={e => onChange(player.id, e)}>
          <option value={""}>None</option>
          <option value={"captain"}>Captain</option>
          <option value={"alternate_captain"}>Alternate Captain</option>
        </Input>
      </FormGroup>

      <StatusInput player={player} onChange={onChange} />
    </FormItem>
  );
}

LineupInput.propTypes = {
  team: PropTypes.oneOf(["home", "visitor"]).isRequired,
  player: PropTypes.shape({}).isRequired,
  stripe: PropTypes.bool,
  refreshJerseyValidity: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default LineupInput;
