import moment from "moment";
import { all, takeLatest, put, select } from "redux-saga/effects";

import { loadGames, exportSeasonGames } from "@/lib/api/games";

import { fetchList, gamesheetAPIRequest } from "@/redux/api/sagas";
import { gamesCountLoadingRoutine, submittingRoutine } from "./routines";
import { getFilter } from "@/redux/completedGamesFilter/selectors";

function* gamesCountLoadingSaga({ payload: { seasonId } }) {
  yield put(gamesCountLoadingRoutine.request());

  const { totalCount } = yield fetchList("games", loadGames, {
    pageNumber: 1,
    pageSize: 1,
    seasonId
  });

  yield put(gamesCountLoadingRoutine.success({ count: totalCount }));
  yield put(gamesCountLoadingRoutine.fulfill());
}
function* submittingSaga({ payload }) {
  const {
    values: { email },
    seasonId,
  } = payload;

  const filterValues = yield select(getFilter);

  yield put(submittingRoutine.request());

  try {
    // Fetch the list of games with the current filters
    const pageSize = 10000; // I am leveraging the fact that the API will return all games if the pageSize is set to a high number. I did this because the api already existed and I wanted to leverage what we have.
    const pageNumber = 1;

    const response = yield fetchList("games", loadGames, {
      pageNumber,
      pageSize,
      seasonId,
      filter: filterValues,
      include: "teams,suspensions",
    });

    // Extract the game IDs from the response
    const { ids: gameIds } = response;

    if (!gameIds || gameIds.length === 0) {
      throw new Error("No games found with the specified filters.");
    }

    // Send the export request with the game IDs
    yield gamesheetAPIRequest(exportSeasonGames, {
      seasonId,
      email,
      timezone: Number(moment().format("ZZ") / 100).toString(),
      gameIds, // Pass the IDs in the request
    });

    yield put(submittingRoutine.success());
  } catch (error) {
    console.error("Error exporting season games:", error);
    yield put(submittingRoutine.failure({ error }));
  } finally {
    yield put(submittingRoutine.fulfill());
  }
}


export function* exportSeasonGamesFlow() {
  yield all([
    takeLatest(gamesCountLoadingRoutine.TRIGGER, gamesCountLoadingSaga),
    takeLatest(submittingRoutine.TRIGGER, submittingSaga)
  ]);
}
