import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";

import { getCount, getIsLoading, getIsLoaded, gamesCountLoadingRoutine } from "@/redux/exportSeasonGames";

import {getFilteredCount} from "@/redux/exportSeasonGames/selectors";

export default function useExportSeasonGamesCount(isOpen) {
  const dispatch = useDispatch();

  const { seasonId } = useCurrentSeasonContext();

  const count = useSelector(getCount);
  const filteredCount = useSelector(getFilteredCount);
  const isLoading = useSelector(getIsLoading);
  const isLoaded = useSelector(getIsLoaded);

  useEffect(() => {
    if (isOpen && !isLoading && !isLoaded) {
      dispatch(gamesCountLoadingRoutine.trigger({ seasonId }));
    }
  });

  return {
    gamesCount: count,
    gamesCountIsLoaded: isLoaded,
    gamesCountIsLoading: isLoading,
    filteredCount
  };
}
