import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Input, FormFeedback, InputGroup, InputGroupText } from "reactstrap";

function CustomLabelInput(props) {
	const { id, name, value, setValue, isInvalid, isDirty, errors, placeholder } =
		props;

	const onChange = useCallback(
		(event) => {
			const inputValue = event.target.value;
			const tbdValue = `TBD - ${inputValue || ""}`;
			setValue(tbdValue);
		},
		[setValue]
	);

	// Get the display value from the TBD string
	const getDisplayValue = () => {
		if (!value) return "";
		return value.startsWith("TBD - ") ? value.substring(6) : value;
	};

	return (
		<InputGroup>
			<InputGroupText className="bg-light text-muted">TBD -</InputGroupText>
			<Input
				invalid={isDirty && isInvalid}
				id={id}
				name={name}
				value={getDisplayValue()}
				onChange={onChange}
				placeholder={placeholder || "E.g. Winner of Pool A"}
			/>
			{isDirty &&
				errors.map((error) => <FormFeedback key={error}>{error}</FormFeedback>)}
		</InputGroup>
	);
}

CustomLabelInput.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	setValue: PropTypes.func.isRequired,
	errors: PropTypes.arrayOf(PropTypes.string),
	isInvalid: PropTypes.bool,
	isDirty: PropTypes.bool,
};

CustomLabelInput.defaultProps = {
	isInvalid: false,
	isDirty: false,
	errors: [],
};

export default CustomLabelInput;
