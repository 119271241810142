import { useState, useEffect } from "react";

import useCurrentSeasonContext from "@/hooks/useCurrentSeasonContext";
import maxJerseyNumberLength from "@/utils/maxJerseyNumberLength";

function useSportJerseyNumLength() {
    const [maxNumLength, setMaxNumLength] = useState(2); // default to 2 digits
    const { season } = useCurrentSeasonContext();

    const sport = season && season.sport ? season.sport.toLowerCase() : "";

    // fetch max num length on sport change
    useEffect(() => {
        if (sport) {
            const data = maxJerseyNumberLength(sport);
            data.then((result) => {
                setMaxNumLength(result);
            });
        }
    }, [sport]);

    return maxNumLength;
}

export default useSportJerseyNumLength;
