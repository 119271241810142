import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "reactstrap";

import FormSection from "@/components/FormSection";

import { useGameGeneralSettingsFields } from "./hooks";

import PeriodLengths from "./PeriodLengths";
import ShootoutSwitch from "./ShootoutSwitch";
import GameCategories from "./GameCategories";
import FormGroup from "reactstrap/lib/FormGroup";
import TitleBar from "../TitleBar";
import Input from "reactstrap/lib/Input";
import ManagerSchedulingSwitch from "./ManagerSchedulingSwitch";
import { Can } from "../Ability";

function GameGeneralSettingsFieldset({ value, onChange }) {
  const {
    periodLengths,
    changePeriodLengths,
    gameCategories,
    changeGameCategories,
    shootout,
    changeShootout,
    signatures_required,
    changeSignaturesRequired,
    default_player_status,
    changeDefaultPlayerStatus,
    manager_scheduling,
    changeTeamManagerScheduling,
    restrict_locked_roster_additions,
    changeRestrictLockedRosterAdditions
  
  } = useGameGeneralSettingsFields({
    value,
    onChange
  });

  return (
    <FormSection title="General">
      <Row>
        <Col md="6">
          <PeriodLengths value={periodLengths} onChange={changePeriodLengths} />

          <ShootoutSwitch enabled={shootout} onChange={changeShootout} />
        </Col>

        <Col md="6">
          <FormGroup>
            <GameCategories value={gameCategories} onChange={changeGameCategories} />
          </FormGroup>

          <FormGroup>
            <TitleBar slim title="Signatures Required on Scoresheet" titleTag="h4" />
            <Input type="select" onChange={changeSignaturesRequired} value={signatures_required}>
              <option value="head_coach">Head Coach</option>
              <option value="all_coaches">All Coaches</option>
              <option value="no_signatures">No Signatures</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <TitleBar slim title="Default Player Status" titleTag="h4" />
            <Input type="select" onChange={changeDefaultPlayerStatus} value={default_player_status}>
              <option value="playing">Playing</option>
              <option value="sitting_out">Sitting Out</option>
            </Input>
          </FormGroup>

          <TitleBar slim title="Teams can Schedule Games" titleTag="h4" />
          <ManagerSchedulingSwitch enabled={manager_scheduling} onChange={changeTeamManagerScheduling} label={<span>Allow designated team personnel to schedule games for their team</span>} id={"manager_scheduling"}/>

          <Can I="create" this={{ type: "seasons"}}>
            <TitleBar slim title="Restrict Game-Time Additions for Locked Rosters" titleTag="h4" />
            <ManagerSchedulingSwitch enabled={restrict_locked_roster_additions} onChange={changeRestrictLockedRosterAdditions} label={<span>Prevent players or coaches from being added at game time when the roster is locked.</span>} id={"restrict_locked_roster_additions"} />
          </Can>
        </Col>
      </Row>
    </FormSection>
  );
}

GameGeneralSettingsFieldset.propTypes = {
  value: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func
};

GameGeneralSettingsFieldset.defaultProps = {
  onChange() {}
};

export default GameGeneralSettingsFieldset;
