import * as React from "react";
import { FlagProvider, useUnleashContext } from "@unleash/proxy-client-react";
import { unleashConfig } from "@/unleash";
import { getAccountEmail } from "@/redux/account";
import { useSelector } from "react-redux";

function UnleashContextUpdater({ children }) {
	const userId = useSelector(getAccountEmail);
	const updateContext = useUnleashContext();
  
	React.useEffect(() => {
	  if (userId) {
		const context = { userId };
		updateContext(context);
	  }
	}, [userId, updateContext]);
  
	return children;
  }

export function UnleashProvider({ children }) {

	return <FlagProvider config={unleashConfig}><UnleashContextUpdater>{children}</UnleashContextUpdater></FlagProvider>;
}
