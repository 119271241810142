import * as React from "react";
import useSportJerseyNumLength from "@/hooks/useSportJerseyNumLength";
import Lineup from "./Lineup";


export default function LineUpWrapper() {
    const maxNumLength = useSportJerseyNumLength();

    return (
        <>
        <Lineup team="visitor" maxNumLength={maxNumLength} />
        <Lineup team="home" maxNumLength={maxNumLength} />
        </>
    )
}