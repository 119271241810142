import { useReducer, useCallback, useMemo } from "react";

import reducer, { init } from "./reducer";
import { setErrors, visitField, resetVisitField } from "./actions";
import useSetFieldValue from "./useSetFieldValue";

export default function useForm(initialValues, baseConstraints) {


  const [state, dispatch] = useReducer(reducer, { constraints: baseConstraints, initialValues }, init);

  const values = Object.entries(state.fields).reduce((result, [name, { value }]) => ({ ...result, [name]: value }), {});

  const constraints = useMemo(() => {
    const isCustomHomeLabel = values && values.home && values.home.team && values.home.team.startsWith("TBD - ");
    const isCustomVisitorLabel = values && values.visitor && values.visitor.team && values.visitor.team.startsWith("TBD - ");
    
    return {
      ...baseConstraints,
      homeLabel: {
        presence: {
          allowEmpty: !isCustomHomeLabel,
        }
      },
      visitorLabel: {
        presence: {
          allowEmpty: !isCustomVisitorLabel,
        }
      }
    };
  }, [baseConstraints, values && values.home, values && values.visitor]);

  const setFieldValue = useSetFieldValue({
    dispatch,
    constraints,
    values
  });

  const dispatchSetErrors = useCallback(payload => dispatch(setErrors(payload)), [dispatch]);

  const dispatchFieldVisit = useCallback(name => dispatch(visitField(name)), [dispatch]);
  
  const reset = () => {

    Object.keys(initialValues).forEach((name) => {
      const value = initialValues[name]
      setFieldValue({name, value})
      dispatch(resetVisitField(name))
    })
  }

  const fields = Object.entries(state.fields).reduce((result, [name, fieldState]) => {
    const setValue = value => setFieldValue({ name, value });
    const setVisited = () => dispatchFieldVisit(name);
    const onChange = ({ target: { value } }) => setValue(value);
    const onBlur = () => setVisited();
   // Check if this is a label field and if its value is just "TBD - "
    const isEmptyLabel = fieldState.value === '' || fieldState.value === 'TBD - ';

    // Include the empty label check in the invalid state
    let isInvalid = fieldState.isInvalid;

    if (values && values.home && values.home.team === 'TBD - Custom Label' && isEmptyLabel && name === 'homeLabel') {
      isInvalid = true;
    }
    if (values && values.visitor && values.visitor.team === 'TBD - Custom Label' && isEmptyLabel && name === 'visitorLabel') {
      isInvalid = true;
    }
    return {
      ...result,
      [name]: {
        ...fieldState,
        isInvalid,
        setValue,
        setVisited,
        input: {
          onChange,
          onBlur,
          value: fieldState.value,
          invalid: (fieldState.isDirty || fieldState.isTouched || fieldState.isVisited) && isInvalid
        }
      }
    };
  }, {});

  const isDirty = !!Object.values(fields).find(({ isDirty }) => isDirty);
  const isValid = Object.values(fields).filter(({ isInvalid }) => isInvalid).length === 0;

  return {
    fields,
    isDirty,
    isValid,
    values,
    isInvalid: !isValid,
    isPristine: !isDirty,
    setErrors: dispatchSetErrors,
    reset
  };
}


